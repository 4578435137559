import {
  RiArrowLeftLine,
  RiLogoutBoxLine,
  RiMapPin2Line,
  RiMenu4Line,
  RiSearchLine,
  RiUserSettingsLine,
} from '@remixicon/react';
import { Button } from '../../components/ui/button';
// import { Badge } from '../../components/ui/badge';
import { useLocation, useNavigate } from '@remix-run/react';
import NotificationsBtn from './notifications-btn';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover';
import { getInitials } from '../../utils/name';
import { prefixUrlWithRole } from '../../utils/navigation';
import { clearSession, getUser } from '../../utils/localStorage';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Logo } from '~/components/logo';
import { useCurrentUserQuery } from '~/hasura';
import Backdrop from '~/components/backdrop';
import CustomerSearch from '~/components/customer-search';
import { useSearchAddress } from '~/hooks/useSearchAddress';

interface IProps {
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  renderCustomerSearch?: boolean;
  setRenderCustomerSearch?: Dispatch<SetStateAction<boolean>>;
}

export default function UserHeader({
  setShowSidebar,
  renderCustomerSearch: renderCustomerSearchProp,
  setRenderCustomerSearch: setRenderCustomerSearchProp,
}: IProps) {
  const { data } = useCurrentUserQuery();
  const navigate = useNavigate();
  const { role } = getUser();
  const { t } = useTranslation();
  const location = useLocation();
  const isAppointmentConfirmation = location.pathname.includes(
    'appointment-confirmation',
  );
  const {
    address,
    form,
    onSearch,
    renderCustomerSearch,
    setRenderCustomerSearch,
  } = useSearchAddress({
    onSearch: () => {
      setRenderCustomerSearchProp?.(false);
    },
  });
  const showCustomerSearch = renderCustomerSearchProp || renderCustomerSearch;
  const setShowCustomerSearch =
    setRenderCustomerSearchProp || setRenderCustomerSearch;

  const user = data?.getCurrentUser;

  const onLogoutHandler = () => {
    clearSession();
    navigate('/login');
  };

  return (
    <>
      <nav className="fixed top-0 z-50 flex w-full items-center justify-between bg-white bg-gradient-to-r from-primary/5 px-6 py-3 shadow-sm">
        {isAppointmentConfirmation ? (
          <Button
            variant="ghost"
            className="flex items-center gap-2 px-0 text-homy-gray-dark"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine className="text-homy-gray-darker" size={24} />
            <p>{t('labels.back')}</p>
          </Button>
        ) : (
          <div className="flex items-center gap-2 sm:gap-6">
            <Button
              size="icon"
              onClick={() => setShowSidebar((showSidebar) => !showSidebar)}
              variant="ghost"
              className="relative"
            >
              {/* todo: use the appointments query count */}
              {/* <Badge
                className="absolute -right-[3px] -top-[3px]"
                variant="notification"
                size="md"
              >
                2
              </Badge> */}
              <RiMenu4Line size={24} className="text-homy-gray-darker" />
            </Button>
            <Logo className="inline-block sm:hidden" />
            <Button
              variant="ghost"
              className="hidden p-0 sm:inline-block"
              onClick={() => setShowCustomerSearch(true)}
            >
              <div className="flex max-w-32 items-center gap-2 sm:max-w-64 md:max-w-96">
                <div className="h-6 w-6">
                  <RiMapPin2Line />
                </div>
                <p className="truncate">{address || user?.address}</p>
              </div>
            </Button>
          </div>
        )}

        <Logo center className="hidden sm:block" />

        <div className="flex items-center gap-2 sm:gap-8">
          <Button
            variant="ghost"
            className="inline-block p-0 sm:hidden"
            onClick={() => setShowCustomerSearch(true)}
          >
            <div className="flex max-w-32 items-center gap-2 sm:max-w-64 md:max-w-96">
              <div className="h-6 w-6">
                <RiMapPin2Line />
              </div>
              <p className="truncate">{address || user?.address}</p>
            </div>
          </Button>
          <Button variant="ghost" className="p-0">
            <RiSearchLine
              onClick={() => setShowCustomerSearch(true)}
              size={24}
              className="text-homy-gray-darker"
            />
          </Button>
          {role && <NotificationsBtn />}
          <Popover>
            <PopoverTrigger className="hidden h-10 w-10 items-center justify-center rounded-3xl border-2 border-white bg-homy-green-dark text-base text-white shadow-md sm:flex">
              {getInitials(user?.name || '')}
            </PopoverTrigger>
            <PopoverContent>
              <Button
                variant="navigation"
                onClick={() => navigate(prefixUrlWithRole('/settings/general'))}
                className="px-4 py-3 hover:border-none"
              >
                <RiUserSettingsLine
                  size={16}
                  className="text-homy-gray-darker"
                />
                <p className="text-homy-gray-darker">
                  {t('links.profileSettings')}
                </p>
              </Button>
              <Button
                variant="navigation"
                onClick={onLogoutHandler}
                className="px-4 py-3 hover:border-none"
              >
                <RiLogoutBoxLine size={16} className="text-destructive" />
                <p className="text-destructive">{t('labels.logout')}</p>
              </Button>
            </PopoverContent>
          </Popover>
        </div>
      </nav>
      {/* <UserSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} /> */}
      <div>
        <Backdrop
          open={showCustomerSearch}
          handleClose={() => setShowCustomerSearch(false)}
        >
          <div className="flex flex-col gap-8 p-4 sm:p-0">
            <h1 className="text-5xl font-bold text-white">
              {t('labels.discoverServicesNearYou')}
            </h1>
            <CustomerSearch form={form} onSubmit={onSearch} />
          </div>
        </Backdrop>
      </div>
    </>
  );
}
